<template>
    <div class="body-box">
        <div class="line" v-for="(item, index) in items" :key="index"
            :style="{ backgroundColor: item.lineStyle.backgroundColor }">
            <div :style="{ width: item.lineStyle.contentWidth, margin: '0 auto' }">
                <a-row class="line-block" :gutter="[item.layNumber > 1 ? item.lineStyle.gutter : 0]"
                    :style="{ paddingTop: item.lineStyle.paddingTop + 'px', paddingBottom: item.lineStyle.paddingBottom + 'px' }">
                    <a-col v-for="(block, col) in item.layNumber" :key="col" :span="parseInt(24 / item.layNumber)">
                        <div class="block-box">
                            <!----模块开始---->
                            <ImageGraph v-if="item.blockData[col].subName == 'image'" :data="item.blockData[col].data"
                                :style="item.blockData[col].style" />
                            <Advs v-else-if="item.blockData[col].subName == 'advs'" :data="item.blockData[col].data"
                                :style="item.blockData[col].style" />
                            <RichEditor v-else-if="item.blockData[col].subName == 'rich'"
                                :data="item.blockData[col].data" :style="item.blockData[col].style" />
                            <Article v-else-if="item.blockData[col].subName == 'article'"
                                :data="item.blockData[col].data" :style="item.blockData[col].style" />
                            <TabList v-else-if="item.blockData[col].subName == 'tab'" :data="item.blockData[col].data"
                                :style="item.blockData[col].style" />
                            <Idefined v-else-if="item.blockData[col].subName == 'idefined'"
                                :data="item.blockData[col].data" :style="item.blockData[col].style" />
                            <Iempty v-else />

                            <div class="block-layer">
                                <div class="block-bar">
                                    <div class="bar-title">块</div>
                                    <div class="bar-item" @click="editBlock(index, col)">设置</div>
                                    <div class="bar-item" @click="deleteBlock(index, col)">清空</div>
                                </div>
                            </div>
                            <!----模块结束---->
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="bar-layer">
                <div class="line-bar">
                    <div class="bar-title">布局</div>
                    <div class="bar-item" @click="editLine(index)">设置</div>
                    <div class="bar-item">编辑</div>
                    <div class="bar-item">
                        <a-tooltip>
                            <template #title>复制</template>
                            <span class="bar-item-text">
                                <CopyOutlined />
                            </span>
                        </a-tooltip>
                    </div>
                    <div class="bar-item">
                        <a-tooltip>
                            <template #title>上移</template>
                            <span class="bar-item-text">
                                <ArrowUpOutlined />
                            </span>
                        </a-tooltip>
                    </div>
                    <div class="bar-item">
                        <a-tooltip>
                            <template #title>下移</template>
                            <span class="bar-item-text">
                                <ArrowDownOutlined />
                            </span>
                        </a-tooltip>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="control-bar-box">
        <div class="item" v-for="(item, index) in items" :key="index" @click="clickItem(index)">
            <a-button type="dashed" block :class="lineActive == index ? 'item-button button-active' : 'item-button'">
                <InteractionOutlined class="item-icon" />
                <span class="item-text">{{ item.lineName }}</span>
                <DeleteOutlined class="item-delete" @click="deleteLine(index)" />
            </a-button>
        </div>
        <a-dropdown>
            <template #overlay>
                <a-menu @click="addLine">
                    <a-menu-item key="1">布局【1】块</a-menu-item>
                    <a-menu-item key="2">布局【2】块</a-menu-item>
                    <a-menu-item key="3">布局【3】块</a-menu-item>
                    <a-menu-item key="4">布局【4】块</a-menu-item>
                    <a-menu-item key="6">布局【6】块</a-menu-item>
                    <a-menu-item key="8">布局【8】块</a-menu-item>
                    <a-menu-item key="12">布局【12】块</a-menu-item>
                </a-menu>
            </template>
            <a-button block class="add-home-item">
                添加首页模块
            </a-button>
        </a-dropdown>
    </div>

    <a-drawer title="设置行" :width="360" placement="left" :closable="false" :visible="lineVisible" @close="onCloseLine">
        <div class="line-modal">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
                <a-form-item label="模块名称">
                    <a-input v-model:value="items[0].lineName" placeholder="请输入模块名称" />
                </a-form-item>
                <a-form-item label="上外边距">
                    <a-slider v-model:value="items[0].lineStyle.paddingTop" :min="0" :max="200" />
                </a-form-item>
                <a-form-item label="下外边距">
                    <a-slider v-model:value="items[0].lineStyle.paddingBottom" :min="0" :max="200" />
                </a-form-item>
                <a-form-item label="栅格间距">
                    <a-slider v-model:value="items[0].lineStyle.gutter" :min="0" :max="200" />
                </a-form-item>
                <a-form-item label="内边距">
                    <a-slider v-model:value="items[0].lineStyle.margin" :min="0" :max="200" />
                </a-form-item>
                <a-form-item label="背景颜色">
                    <ColorPicker picker-type="chrome" format="hex" v-model:pureColor="
                        items[0].lineStyle.backgroundColor
                    " />
                    <span class="color-select-code">Hex：{{ items[0].lineStyle.backgroundColor }}</span>
                </a-form-item>
            </a-form>
        </div>
    </a-drawer>

    <BlockController ref="RefBlockController" :block="items[lineActive].blockData[blockActive]"
        @changeBlockData="changeBlockData" />

</template>
<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import { CopyOutlined, ArrowUpOutlined, ArrowDownOutlined, InteractionOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { ImageGraph, Advs, RichEditor, Article, TabList, Idefined, Iempty, BlockController } from "./block";
import { ColorPicker } from "vue3-colorpicker";

import "./style/body.less";
export default defineComponent({
    components: {
        ColorPicker,
        CopyOutlined,
        ArrowUpOutlined,
        ArrowDownOutlined,
        InteractionOutlined,
        DeleteOutlined,
        ImageGraph,
        Advs,
        RichEditor,
        Article,
        TabList,
        Idefined,
        Iempty,
        BlockController
    },
    props: {
        body: Object,
    },
    setup(props, { emit }) {
        const items = computed({
            get: () => props.body,
            set: (value) => emit("update:body", value),
        });

        const lineActive = ref(0);
        const blockActive = ref(0);

        // 点击活动模块
        const clickItem = (index) => {
            lineActive.value = index;
        };

        // 删除模块
        // 删除模块
        const deleteLine = (index) => {
            items.value.splice(index, 1);
        };

        // ****************行操作****************
        // 添加行
        const addLine = (e) => {
            console.log(e.key);
            let col = Number(e.key);
            let childList = [];

            for (let i = 0; i < col; i++) {
                childList.push({
                    subName: "",
                    subTitle: "",
                    data: {},
                });
            }

            items.value.push({
                lineName: "新模块",
                lineStyle: {
                    margin: 0,
                    paddingTop: 10,
                    paddingBottom: 10,
                    gutter: 20,
                    contentWidth: "1200px",
                    backgroundColor: "#ffffff",
                },
                layNumber: col,
                blockData: childList,
            });
        };

        const lineVisible = ref(false);
        // 设置line
        const editLine = (index) => {
            console.log(index);
            lineVisible.value = true;
        };

        const onCloseLine = () => {
            lineVisible.value = false;
        }

        // ****************块操作****************
        const RefBlockController = ref();
        const editBlock = (index, col) => {
            lineActive.value = index;
            blockActive.value = col;

            RefBlockController.value.onOpenBlock();
        }
        const deleteBlock = (index, col) => {
            items.value[index].blockData[col] = {
                subName: "",
                subTitle: "",
                data: {},
            };
        }

        const changeBlockData = (newBlock) => {
            console.log(8888);
            console.log(newBlock);
        }

        onMounted(() => {
            // console.log(items);
        });

        return {
            items,
            lineActive,
            RefBlockController,
            blockActive,
            clickItem,
            deleteLine,
            lineVisible,
            addLine,
            editLine,
            onCloseLine,
            editBlock,
            deleteBlock,
            changeBlockData
        };
    },
});
</script>