<template>
    <div class="footer-box" :style="{ width: '100%', backgroundColor: item.style.backgroundColor }">
        <div :style="{ width: item.style.contentWidth, margin: '0 auto' }">
            <div class="help-box">
                <a-row justify="center">
                    <a-col :span="16">
                        <div class="help-service">
                            <div class="help-item" v-for="(item) in 5" :key="item">
                                <span class="help-item-span">关于我们</span>
                                <a href="#" class="help-item-a">加入我们</a>
                                <a href="#" class="help-item-a">联系我们</a>
                                <a href="#" class="help-item-a">招商合作</a>
                                <a href="#" class="help-item-a">招商合作</a>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="ewm-box">
                            <a-row>
                                <a-col :span="12">
                                    <div class="ewm-item">
                                        <div class="ewm-title">五佳小程序</div>
                                        <a-image :width="110" :preview="false" style="border-radius:50%"
                                            src="http://tp6.crate.com/public/uploads/image/20220531/142fb9ac12b027f5620ce1749f7745a0.jpg" />
                                    </div>
                                </a-col>
                                <a-col :span="12">
                                    <div class="ewm-item">
                                        <div class="ewm-title">五佳公众号</div>
                                        <a-image :width="110" :preview="false"
                                            src="http://tp6.crate.com/public/uploads/image/20220531/30837ee208ba81a415d5122b3e7e2e02.jpg" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>

                    </a-col>
                </a-row>
            </div>
            <div class="footer-copyrights">
                <p>Copyright ©2012-2022 四川五佳网络科技有限公司 版权所有，并保留所有权利。</p>
                <p>网站备案/许可证号：<a href="https://beian.miit.gov.cn" target="_blank">{{ item.data.icp }}</a><span
                        class="footer-gap">|</span><span>增值电信业务经营许可证：川B2-20210870</span></p>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, computed, onMounted } from "vue";
import "./style/footer.less";
export default defineComponent({
    props: {
        footer: Object,
    },
    setup(props, { emit }) {
        const item = computed({
            get: () => props.footer,
            set: (value) => emit("update:footer", value),
        });

        onMounted(() => { });

        return { item };
    },
});
</script>