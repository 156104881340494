<template>
    <div>
        <a-drawer title="设置模块" :width="360" placement="left" :closable="true" :visible="visibleBlock"
            @close="onCloseBlock">
            <div class="line-modal">
                <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
                    <a-form-item label="模块名称">
                        <a-input v-model:value="item.data.title" placeholder="请输入标题" />
                    </a-form-item>
                    <a-form-item label="模块名称">
                        <a-select v-model:value="item.subName" @change="changeDataType">
                            <a-select-option :value="item.value" v-for="(item, index) in dataType" :key="index">
                                {{ item.title }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="选择数据">
                        <a-select v-model:value="item.category_id" @change="changeDataItems(item.category_id)">
<!--                            <a-select-option value="0" >默认数据</a-select-option>-->
                            <a-select-option :value="item.category_id" v-for="(item, index) in optionsBlock" :key="index" >
                                {{ item.category_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </div>
        </a-drawer>
    </div>
</template>
<script>
import { defineComponent, computed, onMounted, ref, reactive } from "vue";
import * as Api from "../../api";
export default defineComponent({
    props: {
        visible: Boolean,
        block: Object,
    },
    setup(props, { emit }) {
        const item = computed({
            get: () => props.block,
            set: (value) => emit("update:block", value),
        });

        const visibleBlock = ref(false);

        const onOpenBlock = () => {
            visibleBlock.value = true;
        }

        const onCloseBlock = () => {
            visibleBlock.value = false;
        }

        // 模块内容类型
        const dataType = [
            { title: "文档列表", value: "article" },
            { title: "文档详情", value: "article_detail" },
            { title: "幻灯片", value: "advs" },
            { title: "图片", value: "image" },
            { title: "富文本", value: "rich" },
            { title: "TAB标签页", value: "tab" },
            { title: "地图", value: "map" },
            { title: "自定义", value: "idefined" },
        ];

        // 模块内容类型
        const dataTelnet = [
            { title: "新闻中心", value: "4" },
            { title: "关于我们", value: "1" },
            { title: "联系我们", value: "2" },
            { title: "政务公开", value: "3" },
        ];

        //切换数据结构
        const listBlock = ref([]);
        const optionsBlock = ref([]);
        const changeDataType = (value) => {
            // 根据选择的数据类型请求数据
            Api.get_cate_data({
                type: value,
            }).then((res) => {
                optionsBlock.value = res.data;
                newBlock.data = res.data;
                emit("changeBlockData", newBlock);
            });

        };

        const changeDataItems = (value)=>{
            Api.get_list_data({ category_id: value}).then((res) => {
                listBlock.value = res.data;
            });
        };
        // 表单新数据
        const newBlock = reactive({
            data: {
                title: '',
                formID: '0',
                formData: '',
            },
            style: 'sfsfsf'
        });

        onMounted(() => {
            console.log(2222);
            console.log(props.block);
        });

        return {
            item,changeDataItems,listBlock,
            visibleBlock,
            onOpenBlock,
            onCloseBlock,
            dataType,
            optionsBlock,
            changeDataType,
            newBlock,
            dataTelnet
        };
    },
});
</script>