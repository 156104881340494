<template>
    <div>
        <div class="header-box"
            :style="{ width: '100%', backgroundColor: item.style.backgroundColor, height: item.style.height, paddingTop: item.style.paddingTop, paddingBottom: item.style.paddingBottom }">
            <div :style="{ width: item.style.contentWidth, margin: '0 auto' }">
                <a-row :gutter="[item.style.gutter]">
                    <a-col :span="item.style.leftWidth">
                        <div class="header-left-box">
                            <a-image :preview="false" :src="item.data.logo" />
                        </div>
                    </a-col>
                    <a-col :span="item.style.rightWidth">
                        <div class="header-right-box" :style="{ textAlign: 'right' }">
                            <!-- {{ item.data.site_name }} -->
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
        <div class="navigation"
            :style="{ height: item.style.navHeight, backgroundColor: item.style.navBackgroundColor }">
            <div class="nav-box" :style="{ width: item.style.contentWidth, margin: '0 auto' }">
                <div v-for="(nav, navIndex) in treeData" :key="navIndex"
                    :class="navIndex == 0 ? 'nav-item active' : 'nav-item'">
                    <a :style="{ lineHeight: item.style.navLineHeight }">{{ nav.category_name }}</a>
                    <div class="nav-child" v-if="nav.children.length > 0">
                        <div class="nav-item" v-for="(child, childIndex) in nav.children" :key="childIndex">
                            <a :style="{ lineHeight: item.style.navLineHeight }">{{ child.category_name }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, computed, onMounted } from "vue";
import "./style/header.less";
export default defineComponent({
    props: {
        header: Object,
        treeData: Array,
    },
    setup(props, { emit }) {
        const item = computed({
            get: () => props.header,
            set: (value) => emit("update:header", value),
        });


        onMounted(() => { });

        return { item };
    },
});
</script>