<template>
  <a-modal v-model:visible="visible" title="页面管理" @ok="handleSubmit" @cancel="onCancel" width="100%" wrap-class-name="full-modal">
    <div class="template-body">
      <div class="navigation">
        <a-tree style="width:180px" :auto-expand-parent="true" :default-expand-all="true" v-if="treeData.length > 1" v-model:selectedKeys="selectedKeys" :tree-data="treeData" :field-names="fieldNames" @select="selectCategory">
          <template #switcherIcon="{ switcherCls }">
            <down-outlined :class="switcherCls" />
          </template>
        </a-tree>
        <a-skeleton v-else />
      </div>
      <div class="data-body">
        <div class="page-home" v-if="selectedKeys[0] == 0">
          <div class="home-left">
            <div class="home-title">
              首页内容设置
            </div>
            <div class="home-items">
              <div class="item" v-for="(item,index) in formState.items" :key="index" @click="clickHomeItem(index)">
                <a-button type="dashed" block :class="homeActive == index?'item-button button-active': 'item-button'">
                  <InteractionOutlined class="item-icon" />
                  <span class="item-text">{{item.blockName}}</span>
                  <DeleteOutlined class="item-delete" @click="deleteBlock(index)" />
                </a-button>
              </div>

              <a-dropdown>
                <template #overlay>
                  <a-menu @click="addBlock">
                    <a-menu-item key="1">布局【1】块</a-menu-item>
                    <a-menu-item key="2">布局【2】块</a-menu-item>
                    <a-menu-item key="3">布局【3】块</a-menu-item>
                    <a-menu-item key="4">布局【4】块</a-menu-item>
                    <a-menu-item key="6">布局【6】块</a-menu-item>
                    <a-menu-item key="8">布局【8】块</a-menu-item>
                    <a-menu-item key="12">布局【12】块</a-menu-item>
                  </a-menu>
                </template>
                <a-button block class="add-home-item">
                  添加首页模块
                </a-button>
              </a-dropdown>
            </div>
          </div>
          <div class="home-right">
            <WebBlock :dataBlock="formState.items" :activeBlock="formState.active" />
          </div>
        </div>
        <div class="page-page" v-else>
          <div class="data-tab">
            <a-menu mode="inline" class="data-tab-menu" theme="light" v-model:selectedKeys="tabSelectedKeys">
              <a-menu-item key="1" @click="changeTabSelect('1')">
                <span>数据</span>
              </a-menu-item>
              <a-menu-item key="2" @click="changeTabSelect('2')">
                <span>设置</span>
              </a-menu-item>
            </a-menu>
          </div>
          <div class="data-right">
            eeeeeeee
          </div>
        </div>

      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from "vue";
import {
  DownOutlined,
  InteractionOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { WebBlock } from "@/components/Page";
import * as CmsApi from "@/views/document/api";
import * as Api from "@/views/client/api";
import "./style/template.less";
export default defineComponent({
  components: {
    WebBlock,
    DownOutlined,
    InteractionOutlined,
    DeleteOutlined,
  },
  name: "create-template",
  props: ["appid"],
  setup(props, context) {
    // 显示弹框
    const visible = ref(false);

    // 打开弹框
    const showFromModal = (data) => {
      console.log(data);
      visible.value = true;
    };

    const formRef = ref();

    const homeActive = ref(0);
    const clickHomeItem = (index) => {
      homeActive.value = index;
    };
    const formState = reactive({
      tpl_id: "eeeeeeeeeeee",
      active: 0,
      items: [
        {
          blockName: "关于我们",
          blockStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "文档1",
              subName: "article",
              data: {
                title: "文档1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "文档2",
              subName: "article",
              data: {
                title: "文档2标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
        {
          blockName: "轮播图",
          blockStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            backgroundColor: "#ffffff",
          },
          layNumber: 1,
          blockData: [
            {
              subTitle: "广告",
              subName: "advs",
              data: {
                title: "广告标题",
                formID: 1,
                fromData: [
                  "http://tp6.crate.com/public/uploads/image/20220314/5152f48365e0bb907a1a7e564c6d4d02.jpeg",
                  "http://tp6.crate.com/public/uploads/image/20220314/3419bbb2791d4a8f7b95e2ac09584c65.jpeg",
                  "http://tp6.crate.com/public/uploads/image/20220314/cfa9f285e7c3ef87d907af46d39d31a8.jpeg",
                ],
              },
              style: {
                width: "auto",
                height: "auto",
              },
            },
          ],
        },
        {
          blockName: "单图",
          blockStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "单图1",
              subName: "image",
              data: {
                title: "单图1标题",
                formID: 0,
                fromData:
                  "http://tp6.crate.com/public/uploads/image/20220314/5152f48365e0bb907a1a7e564c6d4d02.jpeg",
              },
              style: {
                width: "auto",
                height: "300px",
              },
            },
            {
              subTitle: "单图2",
              subName: "image",
              data: {
                title: "单图2标题",
                formID: 0,
                fromData:
                  "http://tp6.crate.com/public/uploads/image/20220314/5152f48365e0bb907a1a7e564c6d4d02.jpeg",
              },
              style: {
                width: "auto",
                height: "300px",
              },
            },
          ],
        },
        {
          blockName: "新闻资讯|行业新闻",
          blockStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "富文本1",
              subName: "rich",
              data: {
                title: "富文本1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "富文本2",
              subName: "rich",
              data: {
                title: "富文本2标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
        {
          blockName: "关于我们",
          blockStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            backgroundColor: "#ffffff",
          },
          layNumber: 3,
          blockData: [
            {
              subTitle: "文档1",
              subName: "article",
              data: {
                title: "文档1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "文档2",
              subName: "article",
              data: {
                title: "文档2标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "文档3",
              subName: "article",
              data: {
                title: "文档3标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
        {
          blockName: "工作动态",
          blockStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "tab1",
              subName: "tab",
              data: {
                title: "tab1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "tab1",
              subName: "tab",
              data: {
                title: "tab1标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
      ],
    });

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          Api.get_template_update(formState).then(
            (res) => {
              message.success(res.message);
              visible.value = false;
              // 通知父组件刷新列表
              formRef.value.resetFields();
              context.emit("handleSubmit");
            },
            (error) => {
              message.error(error);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {
      // formRef.value.resetFields();
    };

    // 获取左侧分类数据
    const refreshCategoryData = () => {
      CmsApi.getCategoryList().then((res) => {
        if (res.status === 200) {
          res.data.forEach(function (item) {
            treeData.value.push(item);
          });
        } else {
          message.error("栏目数据获取出错了");
        }
      });
    };

    const treeData = ref([
      {
        category_id: 0,
        category_name: "首页",
        children: [],
      },
    ]);

    // 左侧导航打开和选择项
    const expandedKeys = ref([]);
    const selectedKeys = ref([0]);

    //栏目选择
    const selectCategory = (event) => {
      console.log(event);
    };

    onMounted(() => {
      // 获取左侧分类数据
      refreshCategoryData();
    });

    //TAB切换 数据-设置切换
    const tabSelectedKeys = ref(["1"]);
    const changeTabSelect = (key) => {
      tabSelectedKeys.value = key;
    };

    // 首页添加模块
    const addBlock = (e) => {
      console.log(e.key);
      let col = Number(e.key);
      let childList = [];

      for (let i = 0; i < col; i++) {
        childList.push({
          subName: "",
          subTitle: "",
          data: {},
        });
      }

      formState.items.push({
        blockName: "新模块",
        blockStyle: {
          margin: 0,
          padding: 0,
          backgroundColor: "#ffffff",
        },
        layNumber: col,
        blockData: childList,
      });
    };

    // 删除模块
    const deleteBlock = (index) => {
      formState.items.splice(index, 1);
    };

    return {
      formState,
      homeActive,
      formRef,
      visible,
      showFromModal,
      onCancel,
      handleSubmit,
      treeData,
      expandedKeys,
      selectedKeys,
      selectCategory,
      fieldNames: {
        key: "category_id",
        title: "category_name",
        label: "category_name",
        children: "children",
        value: "category_id",
      },
      tabSelectedKeys,
      changeTabSelect,
      clickHomeItem,
      addBlock,
      deleteBlock,
    };
  },
});
</script>