import request from '@/utils/http'

/**
 * 模块
 * @param payload
 */
export function get_block_data(payload) {
    return request({ url: '/client/Block/web', method: 'post', data: payload })
}

/**
 * 模块
 * @param payload
 */
export function get_block_detail(payload) {
    return request({ url: '/client/Block/getDetailByType', method: 'post', data: payload })
}

/**
 * 栏目
 * @param payload
 */
export function get_cate_data(payload) {
    return request({ url: '/client/Block/getCategory', method: 'post', data: payload })
}

/**
 * 列表
 * @param payload
 */
export function get_list_data(payload) {
    return request({ url: '/client/Block/getList', method: 'post', data: payload })
}