<template>
  <div class="idefined-box" :style="{width:style.width,height:style.height,lineHeight:style.height}">
    这里是自定义内容、将会被覆盖
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    style: {
      type: Object,
      default: function () {
        return { width: "auto", height: "100px" };
      },
    },
  },
  setup() {
    return {};
  },
});
</script>
<style lang="less" scoped>
.idefined-box {
  text-align: center;
  border: 1px solid #f0f0f0;
}
</style>