<template>
  <div class="iempty-box">
    <a-empty />
  </div>
</template>
<style lang="less" scoped>
.iempty-box {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px;
}
</style>