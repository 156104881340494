<template>
  <a-tabs v-model:activeKey="activeKey" class="tab-list" :centered="false" :tabBarStyle="{padding:'0 20px'}">
    <a-tab-pane key="1" tab="工作动态">
      <div class="tab-item">
        <div class="list-item" v-for="(item) in 6" :key="item">山一程，水一程，身向榆关那畔行，夜深千帐灯。风一更，雪一更，聒碎乡心梦不成，故园无此声。</div>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="热点聚焦">
      <div class="tab-item">
        <div class="list-item" v-for="(item) in 6" :key="item">11111山一程，水一程，身向榆关那畔行，夜深千帐灯。风一更，雪一更，聒碎乡心梦不成，故园无此声。</div>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="重点新闻">
      <div class="tab-item">
        <div class="list-item" v-for="(item) in 6" :key="item">22222山一程，水一程，身向榆关那畔行，夜深千帐灯。风一更，雪一更，聒碎乡心梦不成，故园无此声。</div>
      </div>
    </a-tab-pane>
    <template #rightExtra>
      <a-button type="link">更多</a-button>
    </template>
  </a-tabs>
</template>
<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  setup() {
    return {
      activeKey: ref("1"),
    };
  },
});
</script>
<style lang="less" scoped>
.tab-list {
  border: 1px solid #f0f0f0;
  .tab-item {
    .list-item {
      width: 100%;
      margin: 6px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>