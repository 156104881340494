<template>
  <div class="image-box">
    <a-image :style="{width:style.width,height:style.height}" :preview="false" :src="data.fromData" />
  </div>

</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    data: Object,
    style: Object,
  },
  setup() {
    return {};
  },
});
</script>
<style lang="less" scoped>
.image-box {
  text-align: center;
}
</style>