<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showTemplateModal()">创建模板</a-button>
      <a-button type="primary" @click="openTemplate()">打开模板</a-button>
    </div>
    <div class="page-attention">
      <p>
        自定义web模板，设置栏目模板样式、显示条目、风格
      </p>
    </div>
    <div class="template-container">
      <a-row :gutter="32">
        <a-col :md="12" :lg="8" :xl="6" v-for="(item, index) in dataSource" :key="index">
          <div class="tpl-item">
            <div class="tpl-item-bg">
              <a href="#" class="item-box"><img :src="item.cover_image" alt="" class="item-box-image" /></a>
              <div v-if="item.used == 1" class="tpl-used">
                <div class="used-box">
                  <CheckCircleOutlined class="used-icon" />
                  <div class="used-title">使用中</div>
                </div>
              </div>
              <div v-else class="tpl-mask">
                <a @click="preview(index)" class="preview">点击预览</a>
                <a @click="usetpl(index)" class="usetpl">立即应用</a>
              </div>
            </div>

            <h3 class="tpl-title"><span>{{ item.title }}</span>
              <a-button type="primary" size="small" class="tpl-dispose" @click="showTemplateManage(item)">管理</a-button>
              <a-button type="text" size="small" class="tpl-dispose" @click="showTemplateModal(item)">设置</a-button>
            </h3>
            <p class="tpl-code">编号 {{ item.code }}</p>
          </div>
        </a-col>
      </a-row>
    </div>
    <CreateTemplate ref="RefCreateTemplate" :appid="refAppid" :templateInfo="RefTemplateInfo" @handleSubmit="handleRefresh" />
    <TemplateManage ref="RefTemplateManage" :appid="refAppid" :templateInfo="RefTemplateInfo" @handleSubmit="handleRefresh" />

    <WebEditor ref="RefWebEditor" />
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { message } from "ant-design-vue";
import { CheckCircleOutlined } from "@ant-design/icons-vue";
import CreateTemplate from "./modules/CreateTemplate";
import TemplateManage from "./modules/TemplateManage";
import { useRoute } from "vue-router";
import * as Api from "@/views/client/api";
import "./style/web.less";

import { WebEditor } from "@/components/Finish";

export default defineComponent({
  components: {
    CheckCircleOutlined,
    CreateTemplate,
    TemplateManage,
    WebEditor,
  },
  setup() {
    //获取当前应用ID
    const route = useRoute();
    const appid = route.query.appid;

    const preview = (index) => {
      message.success("预览" + index);
    };
    const usetpl = (index) => {
      message.success("应用成功" + index);
    };

    const dataSource = ref();
    const onLoadData = () => {
      Api.get_template_list({ appid: appid }).then((res) => {
        // console.log(res);
        dataSource.value = res.data;
      });
    };

    // 打开设置弹框
    const RefCreateTemplate = ref();
    const RefTemplateInfo = ref([]);
    const showTemplateModal = (data = []) => {
      RefCreateTemplate.value.showFromModal(data);
    };

    // 弹框管理弹框
    const RefTemplateManage = ref();
    const showTemplateManage = (data = []) => {
      RefTemplateManage.value.showFromModal(data);
    };

    const handleRefresh = () => {
      onLoadData();
    };

    const RefWebEditor = ref();

    const openTemplate = () => {
      RefWebEditor.value.showFromModal();
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
      openTemplate();
      // showTemplateManage([]);
    });

    return {
      RefCreateTemplate,
      RefTemplateInfo,
      preview,
      usetpl,
      showTemplateModal,
      dataSource,
      handleRefresh,
      refAppid: appid,
      RefTemplateManage,
      showTemplateManage,
      RefWebEditor,
      openTemplate,
    };
  },
});
</script>