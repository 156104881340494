<template>
  <a-modal v-model:visible="visible" title="页面管理" @ok="handleSubmit" @cancel="onCancel" width="100%" wrap-class-name="full-modal">
    <div class="editor-box">
      <WebHeader :header="formState.header" :treeData="treeData" />
      <WebBody :body="formState.body" />
      <WebFooter :footer="formState.footer" />
    </div>
    <div class="page-control" @click="showPageDrawer">
      <a-button type="primary" shape="circle" class="page-control-btn">
        <template #icon>
          <MenuFoldOutlined :style="{ color: '#46be8a' }" />
        </template>
      </a-button>
    </div>
  </a-modal>
  <a-drawer title="选择页面" :width="200" placement="left" :closable="false" :visible="pageVisible" @close="onClosePage">
    <div class="navigation">
      <a-tree :auto-expand-parent="true" :default-expand-all="true" v-if="treeData.length > 1" v-model:selectedKeys="selectedKeys" :tree-data="treeData" :field-names="fieldNames" @select="selectCategory">
        <template #switcherIcon="{ switcherCls }">
          <DownOutlined :class="switcherCls" />
        </template>
      </a-tree>
      <a-skeleton v-else />
    </div>
  </a-drawer>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { WebHeader, WebBody, WebFooter } from "./modules";
import { MenuFoldOutlined, DownOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import * as CmsApi from "@/views/document/api";
import "./style/web.less";
export default defineComponent({
  components: {
    WebHeader,
    WebBody,
    WebFooter,
    MenuFoldOutlined,
    DownOutlined,
  },
  setup() {
    // 打开全屏弹框
    const visible = ref(false);
    const showFromModal = () => {
      visible.value = true;
    };

    // 整个页面数据
    const formState = reactive({
      header: {
        data: {
          site_name: "我的站点",
          logo: "http://tp6.crate.com/public/uploads/image/20220530/6c75a109fd122b18cefbbb4f1e66bd87.png",
        },
        style: {
          contentWidth: "1200px",
          height: "130px",
          gutter: 20,
          margin: "0px",
          leftWidth: 8,
          rightWidth: 16,
          backgroundColor: "#ebf6ff",
          paddingTop: "10px",
          paddingBottom: "10px",
          navHeight: "50px",
          navLineHeight: "50px",
          navBackgroundColor: "#1E8EDA",
          navMargin: "0",
          navPadding: "10px",
        },
      },
      footer: {
        data: {
          icp: "蜀ICP备10001号-1",
        },
        style: {
          contentWidth: "1200px",
          backgroundColor: "#222222",
          margin: "0px",
          color: "#ffffff",
        },
      },
      body: [
        {
          lineName: "轮播图",
          lineStyle: {
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0,
            gutter: 20,
            contentWidth: "100%",
            backgroundColor: "#ffffff",
          },
          layNumber: 1,
          blockData: [
            {
              subTitle: "广告",
              subName: "advs",
              data: {
                title: "广告标题",
                formID: 1,
                fromData: [
                  "http://tp6.crate.com/public/uploads/image/20220530/94ed2d6db4c6930d4523b7f04872841a.jpg",
                  "http://tp6.crate.com/public/uploads/image/20220530/511b3eb084db905559972d0bdb2b19a2.png",
                  "http://tp6.crate.com/public/uploads/image/20220530/3e29414a9d32d1bed904c3216c05e963.jpg",
                ],
              },
              style: {
                width: "auto",
                height: "auto",
              },
            },
          ],
        },
        {
          lineName: "新闻资讯|行业新闻",
          lineStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            contentWidth: "1200px",
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "富文本1",
              subName: "rich",
              data: {
                title: "富文本1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "富文本2",
              subName: "rich",
              data: {
                title: "富文本2标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
        {
          lineName: "关于我们",
          lineStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            contentWidth: "1200px",
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "文档1",
              subName: "article",
              data: {
                title: "文档1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "文档2",
              subName: "article",
              data: {
                title: "文档2标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
        {
          lineName: "单图",
          lineStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            contentWidth: "1200px",
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "单图1",
              subName: "image",
              data: {
                title: "单图1标题",
                formID: 0,
                fromData:
                  "http://tp6.crate.com/public/uploads/image/20220530/d7f7dfe79d2765ffa559b615e0ce2540.jpg",
              },
              style: {
                width: "auto",
                height: "300px",
              },
            },
            {
              subTitle: "单图2",
              subName: "image",
              data: {
                title: "单图2标题",
                formID: 0,
                fromData:
                  "http://tp6.crate.com/public/uploads/image/20220530/5818ad2c4729f8b2bfb4c76d2c7d42f8.jpg",
              },
              style: {
                width: "auto",
                height: "300px",
              },
            },
          ],
        },
        {
          lineName: "新闻资讯|行业新闻",
          lineStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            contentWidth: "1200px",
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "富文本1",
              subName: "rich",
              data: {
                title: "富文本1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "富文本2",
              subName: "rich",
              data: {
                title: "富文本2标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
        {
          lineName: "关于我们",
          lineStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            contentWidth: "1200px",
            backgroundColor: "#ffffff",
          },
          layNumber: 3,
          blockData: [
            {
              subTitle: "文档1",
              subName: "article",
              data: {
                title: "文档1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "文档2",
              subName: "article",
              data: {
                title: "文档2标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "文档3",
              subName: "article",
              data: {
                title: "文档3标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
        {
          lineName: "工作动态",
          lineStyle: {
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            gutter: 20,
            contentWidth: "1200px",
            backgroundColor: "#ffffff",
          },
          layNumber: 2,
          blockData: [
            {
              subTitle: "tab1",
              subName: "tab",
              data: {
                title: "tab1标题",
                formID: 0,
                fromData: null,
              },
            },
            {
              subTitle: "tab1",
              subName: "tab",
              data: {
                title: "tab1标题",
                formID: 0,
                fromData: null,
              },
            },
          ],
        },
      ],
    });

    //提交保存整个模板
    const handleSubmit = () => {};

    //取消关闭、不保存
    const onCancel = () => {};

    // 左侧选择页面
    const pageVisible = ref(false);

    // 显示页面抽屉
    const showPageDrawer = () => {
      pageVisible.value = true;
    };

    // 关闭页面抽屉
    const onClosePage = () => {
      pageVisible.value = false;
    };

    // 获取左侧分类数据
    const refreshCategoryData = () => {
      CmsApi.getCategoryList().then((res) => {
        if (res.status === 200) {
          res.data.forEach(function (item) {
            treeData.value.push(item);
          });
        } else {
          message.error("栏目数据获取出错了");
        }
      });
    };

    const treeData = ref([
      {
        category_id: 0,
        category_name: "首页",
        children: [],
      },
    ]);

    // 左侧导航打开和选择项
    const expandedKeys = ref([]);
    const selectedKeys = ref([0]);

    //栏目选择
    const selectCategory = (event) => {
      console.log(event);
      pageVisible.value = false;
    };

    onMounted(() => {
      // 获取左侧分类数据
      refreshCategoryData();
    });

    return {
      visible,
      formState,
      showFromModal,
      handleSubmit,
      onCancel,
      pageVisible,
      showPageDrawer,
      onClosePage,
      treeData,
      expandedKeys,
      selectedKeys,
      selectCategory,
      fieldNames: {
        key: "category_id",
        title: "category_name",
        label: "category_name",
        children: "children",
        value: "category_id",
      },
    };
  },
});
</script>